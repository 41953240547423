body {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}
